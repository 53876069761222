import { CopyProperties } from '../decorator/copy-properties.decorator';
@CopyProperties()
export class UserProfile {
    public id: number;
    public code: string;
    public name: string;
    public nickname: string;
    public email: string;
    public birthDate: string;
    public passport: string;
    public passportDateOfIssue: string;
    public passportDateOfIssueModel: any;
    public passportPlaceOfIssue: any;
    public passportFrontFaceUrl: string;
    public passportBackFaceUrl: string;
    public selfieUrl: string;
    public phone: string;
    public address: string;
    public provinceId: any;
    public commissionRate: number;
    public bankId: any;
    public bankBranch: string;
    public bankAccountName: string;
    public bankName: string;
    public bankNumber: string;
    public agencyPartnerCode: string;
    public partnerCode: string;
    public certificates: any;
    public profileStep: number;
    public trialStartAt: number;
    public trialExpireAt: number;
    public dayUseLeft: number;
    public status: any;
    public idTypeId: any;
    public educationOrganization: any;
    public marketAssesment: any;
    public rejectNote: string;
    public avatarUrl: string;
    public issuerIds: string;
    public gender: boolean;
    public isConsultant: boolean;
    public isTrialAgency: boolean;
    public isCheckFullPrivateInfo: boolean;
    constructor(
        params?: any
    ) {
        this.id = undefined;
        this.code = '';
        this.name = '';
        this.nickname = '';
        this.birthDate  = null;
        this.passport = '';
        this.passportDateOfIssue = '';
        this.passportPlaceOfIssue = '';
        this.passportFrontFaceUrl = '';
        this.passportBackFaceUrl = '';
        this.selfieUrl = '';
        this.phone = '';
        this.address  = '';
        this.provinceId = null;
        this.email = '';
        this.commissionRate = null;
        this.bankId = null;
        this.bankAccountName = '';
        this.bankBranch = '';
        this.bankName = '';
        this.bankNumber = '';
        this.agencyPartnerCode = '';
        this.partnerCode = '';
        this.certificates = null;
        this.status = null;
        this.idTypeId = null;
        this.educationOrganization = null;
        this.rejectNote = '';
        this.issuerIds = '';
        this.avatarUrl = '';
        this.profileStep = 0;
        this.trialStartAt = null;
        this.trialExpireAt = null;
        this.dayUseLeft = null;
        this.gender = false;
        this.isConsultant = false;
        this.isTrialAgency = false;
        this.isCheckFullPrivateInfo = false;
        this.marketAssesment = null;
    }
}